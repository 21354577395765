<template>
  <div>
    <TheHeader />
    <router-view :key="$route.fullPath"/>
    <TheFooter />
  </div>
</template>

<script>
  // import everything this template uses
  // @ is an alias to /src
  import TheHeader from '@/components/TheHeader.vue'
  import TheFooter from '@/components/TheFooter.vue'

  // export this template and stuff needed by sub components (in this case the real views)
  export default {
    name: 'BaseLayout',
    components: {
      TheHeader,
      TheFooter
    },

    beforeRouteUpdate(to, from, next) {
      this.$log.debug('{BaseLayout} beforeRouteUpdate called, to:', to);
      next();
    },

  }
</script>


<!--
From https://pablohpsilva.github.io/vuejs-component-style-guide/#
<template lang="html">
  <div class="Ranger__Wrapper">
    &lt;!&ndash; ... &ndash;&gt;
  </div>
</template>

<script type="text/javascript">
  export default {
    // Do not forget this little guy
    name: 'RangeSlider',
    // share common functionality with component mixins
    mixins: [],
    // compose new components
    extends: {},
    // component properties/variables
    props: {
      bar: {}, // Alphabetized
      foo: {},
      fooBar: {},
    },
    // variables
    data() {},
    computed: {},
    // when component uses other components
    components: {},
    // methods
    watch: {},
    methods: {},
    // component Lifecycle hooks
    beforeCreate() {},
    mounted() {},
  };
</script>

<style scoped>
  .Ranger__Wrapper { /* ... */ }
</style>-->


<!-- good example of when to use this.$refs -->
<!--
<modal ref="basicModal">
  <h4>Basic Modal</h4>
  <button class="primary" @click="$refs.basicModal.hide()">Close</button>
</modal>
<button @click="$refs.basicModal.open()">Open modal</button>

&lt;!&ndash; Modal component &ndash;&gt;
<template>
  <div v-show="active">
    &lt;!&ndash; ... &ndash;&gt;
  </div>
</template>

<script>
  export default {
    // ...
    data() {
      return {
        active: false,
      };
    },
    methods: {
      open() {
        this.active = true;
      },
      hide() {
        this.active = false;
      },
    },
    // ...
  };
</script>-->


<!--
Let's say you have a mobile and desktop menu component whose share some functionality. We can abstract the core functionalities of both into a mixin like this.

const MenuMixin = {
  data () {
    return {
      language: 'EN'
    }
  },

  methods: {
    changeLanguage () {
      if (this.language === 'DE') this.$set(this, 'language', 'EN')
      if (this.language === 'EN') this.$set(this, 'language', 'DE')
    }
  }
}

export default MenuMixin
To use the mixin, simply import it into both components (I only show the mobile component).

<template>
  <ul class="mobile">
    <li @click="changeLanguage">Change language</li>
  </ul>
</template>

<script>
  import MenuMixin from './MenuMixin'

  export default {
    mixins: [MenuMixin]
  }
</script>
-->

