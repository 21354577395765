/**
 * Auth state
 *
 * This store holds state pertaining to authentication and authorisation.
 *
 * NB this is a Vuex store module so there is no Vuex specifically in here, see index.js up one directory.
 */

import Vue from 'vue'
import {AXIOS} from '../../mixins/axios'

// import router from '@/router';

const isLive = (document.location.host === 'untangl.co.uk');

//Logging
import VueLogger from 'vuejs-logger';
const options = {
  isEnabled: true,
  logLevel : isLive ? 'info' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

// Add cookie support
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

let vm = new Vue();
// vm.$log.debug('****TEST**** Vuex auth starting... ', process.env.NODE_ENV);


// LOCAL Methods

/**
 * _extractLoginDomain - if it detects localhost then it returns 'localhost:60005' else it returns
 * the full domain.
 * NB you need untanglment running locally on port 60005 for login to work.
 *
 * @param domainIn - e.g. one.untangl.co.uk, localhost
 * @return {*|string|*} - e.g. oneuntangl.co.uk, localhost:60005
 * @private
 */
const _extractLoginDomain = function(domainIn) {
  if (domainIn.search(/localhost/) !== -1) {
    vm.$log.debug("{_extractLoginDomain} returning localhost");
    return 'localhost:60005';
  }
  return domainIn; //return the whole host
};


/**
 * _extractDomain - extracts a base domain from a full domain
 *
 * @param domainIn - e.g. one.untangl.co.uk
 * @return {*|string|*} - e.g. untangl.co.uk
 * @private
 */
const _extractDomain = function(domainIn) {
  //cannot do atomic groups with JS so the first alternate group gives us a match i.e. length = 3
  const domainRX = /^(untangler2|untangler|one|admin)\.(\w+(?:\.\w+)*)$/;
  let r = domainIn.match(domainRX);
  vm.$log.debug("{_extractLoginDomain} match gives",r,{});
  if (r == null) {
    return domainIn; //cannot detect any subdomain
  }
  else {
    if (r.length === 3) {
      return r[2]; //core domain
    }
    else {
      return domainIn; //don't know what it is so play safe
    }
  }
};

// vm.$log.debug(`{auth.js} _extractLoginDomain(document.location.host): ${_extractLoginDomain(document.location.host)}`); //

const domain = _extractLoginDomain(document.location.host); //NB domain also in context, duplicated for convenience
// const protocol = domain === 'untangl.co.uk' ? 'https://' : 'http://';
const domainForCookies = _extractDomain(document.location.host);
const cookieSettings = [0,'/', domainForCookies];
const cookieRemoveSettings = ['/', domainForCookies];


const _storeLocal = function(data) {
  vm.$cookies.set('token', data.token, ...cookieSettings);
  vm.$cookies.set('first_name', data.first_name, ...cookieSettings);
  vm.$cookies.set('last_name', data.last_name, ...cookieSettings);
  vm.$cookies.set('rep_uname', data.rep_uname, ...cookieSettings);
  vm.$cookies.set('jurisdictions', data.jurisdictions, ...cookieSettings);
};


const _clearLocal = function() {
  vm.$cookies.remove('token', ...cookieRemoveSettings);
  vm.$cookies.remove('first_name', ...cookieRemoveSettings);
  vm.$cookies.remove('last_name', ...cookieRemoveSettings);
  vm.$cookies.remove('email', ...cookieRemoveSettings);
  vm.$cookies.remove('rep_uname', ...cookieRemoveSettings);
  vm.$cookies.remove('jurisdictions', ...cookieRemoveSettings);
};


// initial state
// *************
let state = {
  token: vm.$cookies.get('token') || '',
  user : {
    first_name: vm.$cookies.get('first_name') || '',
    last_name: vm.$cookies.get('last_name') || '',
    rep_uname: vm.$cookies.get('rep_uname') || '',
    jurisdictions: vm.$cookies.get('jurisdictions') || '',
  },
  domain: _extractLoginDomain(document.location.host), //NB never changes
  cookieDomain: _extractDomain(document.location.host), //NB never changes
};


/*
Vuex allows us to define "getters" in the store. You can think of them as computed properties for stores. Like computed properties, a getter's result is cached based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
*/
// getters
// *******
const getters = {
  isAuthenticated: state => !!state.token,
  userFirstName: state => state.user.first_name || 'Anon',
  getProfileName:  state => state.user.rep_uname ,
  /**
   * Gets dummy userID - just give back a pretend userID to keep the Swagger interface happy
   *
   * @return {String} userID
   */
  getDummyUserID:  () => "123456789123456789123456",
  protocol: state => state.domain === 'untangl.co.uk' ? 'https://' : 'http://' ,
  // Reading this directly from store via rootStore did not work
  getDomain:  state => state.domain ,
  getCookieDomain:  state => state.cookieDomain ,
  getToken:  state => state.token ,

};

// mutations
// *********
const mutations = {

  auth_success(state, data){
    vm.$log.debug(`{mutation} auth_success data: `,data,{});
    // state.loading = false;
    // state.loaded = true;
    state.token = data.token;
    state.user = {
      first_name: data.first_name,
      last_name: data.last_name,
      rep_uname: data.rep_uname || 'uu',
      jurisdictions: data.jurisdictions,
    };
  },

  logout(state){
    state.token = '';
    state.user = {};
  }

};

/*
Actions are similar to mutations, the differences being that:
  Instead of mutating the state, actions commit mutations.
  Actions can contain arbitrary asynchronous operations.
*/

// actions
// *******
const actions = {

  /**
   * SIGNINACTION - try to log a user in
   *
   * @param commit - Vuex commit
   * @param credentials {Object} - contains username and password properties
   * @return {Promise<boolean>}
   */
  async SIGNINACTION({commit, rootGetters, dispatch}, {params}) {
    // try and signin
    const signinOK = await AXIOS({
      init : ({commit, rootGetters, dispatch}, params) => {
        vm.$log.debug("{SIGNINACTION} getters:", getters, {});
        vm.$log.debug("{SIGNINACTION} rootGetters:", rootGetters, {});
        vm.$log.debug("{SIGNINACTION} state:", state, {});
        vm.$log.debug("{SIGNINACTION} commit:", commit, {});
        const urlBase = '/authentication';
        const req = {
          method: 'POST',
          //NB local module getter etc.
          url: rootGetters['auth/protocol'] + rootGetters['auth/getDomain'] + urlBase,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {"email": params.uname, "password": params.pw}
        };
        return req;
      },
      response : async ({commit, rootGetters, dispatch}, jsendResp) => {
        //all OK process normally
        vm.$log.debug("{SIGNINACTION} jsendResp.data:", jsendResp.data);
        //update store values and cookies
        _storeLocal(jsendResp.data);
        commit('auth_success', jsendResp.data);
        return true; //indicates success
      },
      failing : async ({commit, rootGetters, dispatch}, jsendResp) => {
        vm.$log.debug("{SIGNINACTION} jsendResp:", jsendResp);
        _clearLocal();
      }
    })({commit, rootGetters, dispatch}, {params});
    // Get the profile if we have signed in OK
    if (signinOK) {
      // Set profile
      const profileName = rootGetters['auth/getProfileName'];
      const profileOK = await dispatch('profile/setProfile',profileName,{root:true});
      return profileOK;
    }
    else {
      return signinOK; //sign-in NOT ok
    }
  },


  //TODO
  /*
    register({commit}, user){
      return new Promise((resolve, reject) => {
        axios({url: 'http://localhost:3000/register', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem('token', token);
            // Add the following line:
            axios.defaults.headers.common['Authorization'] = token;
            commit('auth_success', token, user);
            resolve(resp);
          })
          .catch(err => {
            localStorage.removeItem('token');
            reject(err);
          })
      })
    },
  */

  SIGNOUTACTION({commit}){
    _clearLocal(); //remove cookies
    commit('resetState', null, { root: true }); //reset all state (apart from auth)
    commit('logout'); //reset state in auth i.e. sign-out
    vm.$log.debug("{SIGNOUTACTION} remove token gives:", vm.$cookies.remove('token'));
    return true;
  }
};

/*
The only way to actually change state in a Vuex store is by committing a mutation. Vuex mutations are very similar
to events: each mutation has a string type and a handler. The handler function is where we perform actual state
modifications, and it will receive the state as the first argument:
You cannot directly call a mutation handler. Think of it more like event registration: "When a mutation with type
increment is triggered, call this handler."
You can pass an additional argument to store.commit, which is called the payload for the mutation:
e.g store.commit('increment', 10)
In most cases, the payload should be an object so that it can contain multiple fields, and the recorded mutation
will also be more descriptive.
*/



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  _extractLoginDomain //testing only
}
