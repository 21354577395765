/**
 * When we fire up (from the / state 'root') the profile is always assumed to be
 * the default Untangl Untangler profile 'uu'. In the 'root' state a check is
 * then made to see if the user is logged in and if they are a suitable profile
 * retrieved and a redirect made to a different site if needed (on state exit).
 *
 * In the 'site' state a check of repid is made via  a call to set profile and a new profile
 * retrieved if necessary. Other than during the initial load this will generally do
 * nothing as the representative is unlikely to have changed.
 *
 * NB this is a Vuex store module so there is no Vuex specifically in here, see store.js up one directory.
 */

import axios from 'axios';
//Logging
/*
import Vue from 'vue'

import VueLogger from 'vuejs-logger';
const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

let vm = new Vue();
// vm.$log.debug('****TEST**** Whats our host', location.host);
// vm.$log.debug('****TEST**** Whats our environment ', process.env.VUE_APP_CONTENTFUL_SPACE);
// vm.$log.debug('****TEST**** Whats our node ', process.env.NODE_ENV);
*/

// initial state
// *************
const defaultProfile = {
  packages:
    {
      standard: {
        benefits: [
          "axa_ppp",
          "canada_life",
          "westfield_health_foresight2",
          "untangl_fee"]
      }
    },
  landf: {
    logo: "https://s3-eu-west-1.amazonaws.com/docs-untangl-io/logos/untangl_small_white46@2x.png",
    tagline: "Intelligent Data Transformation",
    strapline: "Save hours of manual data processing reducing costs and errors"
  },
  contact: {
    type: "us",
    name: "Untangl",
    tel: "020 7043 2550",
    email:"support@untangl.co.uk",
    fb:"https://www.facebook.com/untangl",
    google:"https://plus.google.com/b/101428410070350027132/+UntanglUkhome",
    twitter:"https://twitter.com/untangl_uk",
    linkedin:"https://www.linkedin.com/company/untangl"
  },
  uname: "uu"
};

const state = defaultProfile;
// const state = {profile: defaultProfile};


/*
Vuex allows us to define "getters" in the store. You can think of them as computed properties for stores. Like computed properties, a getter's result is cached based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
*/
// getters
// *******


/*
Actions are similar to mutations, the differences being that:
  Instead of mutating the state, actions commit mutations.
  Actions can contain arbitrary asynchronous operations.
*/

// actions
// *******


/*
The only way to actually change state in a Vuex store is by committing a mutation. Vuex mutations are very similar to events: each mutation has a string type and a handler. The handler function is where we perform actual state modifications, and it will receive the state as the first argument:
You cannot directly call a mutation handler. Think of it more like event registration: "When a mutation with type increment is triggered, call this handler."

You can pass an additional argument to store.commit, which is called the payload for the mutation:
e.g store.commit('increment', 10)
In most cases, the payload should be an object so that it can contain multiple fields, and the recorded mutation will also be more descriptive.
*/

// mutations
// *********


export default {
  namespaced: true,
  state,
  // getters,
  // actions,
  // mutations
}