<!--
This component is used to render a compound page comprising a number of "pagelets", for example
the About page. The router turns the requested path into a named view, that view name needs to
correspond to a page title in the CMS. The page in the CMS will be of "compound" content type
which will return a number of separate blocks of data representing each pagelet. Each pagelet
will have a "type", each type has a corresponding component used to render the data.

This Home page is derived from CompoundPage and could probably replace it. It is used for more
than just the home page (e.g. Products too).
-->

<template>
  <div v-if="pagelets">
<!--    <div>-->
      <Pagelet :pageData="pagelet"
               :type="pagelet.fields.type"
               :key="pagelet.title"
               v-for="pagelet in pagelets" />
<!--    </div>-->
  </div>
</template>

<script>
import Pagelet from '../components/Pagelet.vue'

/*
let initObj = {
  loading: false,
  error: null,
  pagelets: null, //NB v-if uses this to hide div when no data
  pageName: ""
};
*/

export default {
  name: 'CP',
  components: {
    Pagelet
  },
  data: function () {
    return {
      loading: false,
      error: null,
      pagelets: null, //NB v-if uses this to hide div when no data
      pageName: "",
      // SEO fields
      title: 'Untangl Information',
      description: 'Information on Untangl, our products and people',
      robots: 'index,follow'
    }
  },

/*
  watch: {
    '$route' (to, from) {
      // React to route changes...
      this.$log.debug('{Compound} watch $route this.$route.name:', this.$route.name);
      //this.reset();
      this.pageName = this.$route.name;
      this.getAllData(this.pageName);
    }
  },
*/

  /**
   * created() is a lifecycle hook. When the component is reused, it is not called.
   * getData() is called here the first time and subsequently from beforeRouteUpdate.
   * The router *must* be configured with names so that we know what page to fetch
   * The page name must equal the CMS page title for a Compound Page
   */
  created() {
    this.pageName = this.$route.name;
    let subPage = null;
    if (this.$route.params.arg) {
      subPage = this.$route.params.arg;
    }
    this.$log.debug('{Compound} created this.pageName', this.pageName, ', $route.params.arg:', this.$route.params.arg);
    this.getData(this.pageName, subPage);
  },

  beforeRouteUpdate(to, from, next) {
    this.$log.debug('{Compound} beforeRouteUpdate called, to:', to);
    let pageStem = to.name;
    let subPage = null;
    if (to.params.arg) {
      subPage = to.params.arg;
    }
    this.getData(this.pageName, subPage)
      .then(next);
    // next();
  },

  methods: {

    // The compound page fetched is called <Page>(-arg) e.g. tech page for one is 'info' with
    // arg 'tech-one' i.e. info-tech-one
    getData: async function (pageStem, subPage) {
      this.$log.debug(`{Compound} getData called, pageStem: ${pageStem} subPage: ${subPage} ` );
      this.error   = this.pagelets = null;
      this.loading = true;
      let pageName = pageStem;
      if (subPage) {
        pageName = pageName+'-'+subPage;
      }

      const query = {
        'content_type': "compound",
        'fields.page': pageName,
        'include': 10
      };

      try {
        let res = await this.$contentful.getEntries(query);
        let pageletsTemp = res.includes.Entry; //[0].fields...;
        // pagelets.forEach(pagelet => { this.$log.debug("title:", pagelet.fields.title, ", type:", pagelet.fields.type);});
        pageletsTemp = pageletsTemp.filter(p => // Only Video/BannerPane!!!
          (p.fields.type === 'BannerPane' || p.fields.type === 'VideoPane' ) && p.fields.order >= 0);
        //pagelets = pagelets.filter(p.fields.order >= 0);
        pageletsTemp = pageletsTemp.sort((a, b) => { return a.fields.order - b.fields.order;});  // Sort by Order field
        this.loading = false;
        pageletsTemp.forEach(p => { this.$log.debug("title:", p.fields.title, ", order:", p.fields.order); });
        this.pagelets = pageletsTemp;

        //Save SEO data if there is any
        let fields = res.items && res.items[0] && res.items[0].fields ?
            res.items[0].fields :
            {};
        if (fields.title) {
          this.title = fields.title;
        }
        if (fields.seoDescription) {
          this.description = fields.seoDescription;
        }
        if (fields.seoRobots) {
          this.robots = fields.seoRobots;
        }
      }
      catch (error) {
        this.$log.debug('{Compound} getAllData failed with error:', JSON.stringify(error, null, 2));
        this.loading = false;
        this.error   = error;
      }
    }
  },

  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          property: 'og:title',
          content: this.title,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.description,
          vmid: 'og:description'
        },
        {
          vmid: 'description',
          name: 'description',
          content:  this.description
        },
        {
          vmid: 'robots',
          name: 'robots',
          content: this.robots
        }
      ]
    }
  }
}

</script>

<!--
<meta name="title" content="Untangler® - intelligent spreadsheet conversion technology" untangl-controlled>
<meta name="description" content="Automatically recognise and transform inconsistent spreadsheets (lists, schedules of assets, etc.) to your internal requirements so you and your team don't waste valuable time on reformatting and error checking tasks." untangl-controlled>
-->
