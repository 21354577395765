import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/store";

import BaseLayout from '@/views/BaseLayout.vue'
import Compound from '@/views/Compound.vue'
// import Test from '@/views/Test.vue'
// import Blog from '@/views/Blog.vue'
// import BlogItem from '@/views/BlogItem.vue'
// import FAQs from '@/views/FAQs.vue'
// import Api from '@/views/Api.vue'
// import BookPage from "@/views/BookPage";

//local methods
const isLive = (document.location.host === 'untangl.co.uk');

/**
 * _calcExtRef - calculate the URL for an "external" reference i.e. not this application
 *
 * @param subdomain {string} - e.g. 'one' or 'untangler'
 * @param path {string} - e.g. '/u/quote'
 * @return {string}
 * @private
 */
const _calcExtRef = function(subdomain, path) {
  let baseDomain = document.location.host;
  return (isLive?"https://":"http://")+
    subdomain+"."+baseDomain+path;
};


// By injecting the router, we get access to it as this.$router as well as the
// current route as this.$route inside of any component:
Vue.use(VueRouter); //load the router plugin into Vue

//Logging
import VueLogger from 'vuejs-logger';
const options = {
  isEnabled: true,
  logLevel : isLive ? 'info' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

let vm = new Vue();

//set up our routes and global guards
const router = new VueRouter({
  mode: "history", //HTML5 history mode - no #

  routes: [
    {
      path: '/',
      component: BaseLayout, //this gives us a nav and a footer
      children: [
        {
          path: '',
          name: 'home', //by giving a name the route can be referenced <router-link :to="{name:'home'}">Home</router-link>
          component: Compound,  //Home
          meta: {
            metaTags: [
              {
                name: 'title',
                content: 'Untangl - home page'
              },
              {
                name: 'description',
                content: "Untangler® automatically recognises and transforms inconsistent spreadsheets (lists, schedules of assets, etc.) to your internal requirements so you and your team don't waste valuable time on reformatting and error checking tasks."
              }
            ],
            showChat: true //this is the default so is not repeated below
          }
        },
        { //todo not enabled yet
          path: 'api/:bookname/:slug',
          name: 'api',
          component: () => import('../views/Api.vue'), //webpackChunkName: "api"
          meta: {
            showChat: false
          }
        },
        {
          path: 'book/:bookname/:slug?', //the ? means its an optional dynamic segment
          name: 'book',
          component: () => import('../views/BookPage.vue'), //webpackChunkName: "bookpage"
          meta: {
            showChat: false,
          }

        },
        {
          path: 'untangler',
          name: 'untanglerjump',
          // a redirect prevents beforeEnter global guard running - but what about beforeEach
          redirect: 'products/untangler',
          // redirect: { name: 'products', params: {arg: 'untangler'} },
        },
        { //Untangler has moved page - for CL compatibility
          path: 'untangler/:repid',
          name: 'untangler',
          component: Compound,
          meta: {
            showChat: false
          }
        },
        {
          path: 'blog',
          name: 'blog',
          component: () => import('../views/Blog.vue'), //webpackChunkName: "blog"
        },
        {
          path: 'blog/:slug',
          name: 'blogItem',
          component: () => import('../views/BlogItem.vue'), //webpackChunkName: "blogitem"
          props: true, //to pass the :slug as a prop rather than use $route.params.*
        },
        {
          path: 'faqs/:arg',
          name: 'faqs',
          component: () => import('../views/FAQs.vue'), //webpackChunkName: "faqs"
        },
        {
          path: 'products/:arg',
          name: 'products',
          component: Compound,
        },
        {
          path: 'productslegacy/:arg',
          name: 'productslegacy',
          component: Compound,
        },
        {
          // can be info-about, -contact, -technology, -privacy, -support, -videos, -downloads, -resources
          // -key-features-untangler, tech-untangler, -pricing, (-other-products)
          // -re, -eb, -life
          path: 'info/:arg',
          name: 'info',
          component: Compound
        },
        //External named locations - NB 'external/...' does not exist, just causes calc'd href to be loaded
        {
          path: 'external/quote',
          name: 'quote',
          beforeEnter(to, from, next) {
            Vue.$ga.event('one', 'getquote');
            let href = _calcExtRef("one", "/u/quote");
            window.open(href,"untanglapp");
            next(false);
          }
        },
        {
          path: 'external/quotesignin',
          name: 'quotesignin',
          beforeEnter(to, from, next) {
            Vue.$ga.event('one', 'signin');
            let href = _calcExtRef("one", "/u");
            window.open(href,"untanglapp");
            next(false);
          }
        },
        {
          path: 'external/untanglersignin',
          name: 'untanglersignin',
          beforeEnter(to, from, next) {
            Vue.$ga.event('untangler', 'signin');
            let href = _calcExtRef("untangler", "/u");
            window.open(href,"untanglapp");
            next(false);
          }
        },
        // catch all redirect for any other path entered
        {
          path: '*',
          redirect: '/'
        }
      ]
    }
  ]
});

//global router navigation guard to update Vuex state based on page
//NB this must be registered BEFORE Vue initialised with router in main.js
router.beforeEach((to, from, next) => {
  vm.$log.debug('{beforeEach} in router.js running, "to" is:', to);

  //AUTHENTICATION CHECK TODO
  // (All route records matched by a route are exposed on the $route object (and also route objects in
  // navigation guards) as the $route.matched Array)
  /*
    if (to.matched.some(record => record.meta.requiresAuth)) {
      vm.$log.debug(`{beforeEach Router} store.getters['auth/isAuthenticated']: ${store.getters['auth/isAuthenticated']}`);
      // this route requires auth, check if logged in by using status in Vuex store
      // if not, redirect to login page.
      if (!store.getters['auth/isAuthenticated']) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    }
  */


  // SAVE NEW PAGE
  store.commit('menu/pageUpdate', to);

  next();


});

router.afterEach((to) => {
  Vue.prototype.$tidioChatApi &&
  Vue.prototype.$tidioChatApi.display(to.meta.showChat);
});

export default router;
