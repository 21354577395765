/*This is the application entry point*/

import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker'
import router from './router/router';
import store from './store/store';
import * as Contentful from 'contentful';

//Set this to false to prevent the production tip on Vue startup:
// "Note that the development build is not optimized.
//   To create a production build, run npm run build."
Vue.config.productionTip = false;
//flag used in various places below to see if we are live or not
const isLive = (document.location.host === 'untangl.co.uk');
// const isLive = true;

//fontawesomes that we want to use throughout
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
//icons themselves
// use like this <font-awesome-icon :icon="['fas', 'compress-alt']" size="1x">
// <font-awesome-icon :icon="['fal', 'compress-alt']" size="1x">
// <font-awesome-icon :icon="['far', 'compress-alt']" size="1x"> etc. etc.
import { faCircle, faEnvelope, faAngleDown, faCompressAlt, faExpandAlt} from '@fortawesome/pro-solid-svg-icons'
import {  faArrowToLeft as farArrowToLeft,  faArrowToRight as farArrowToRight,
  faEnvelope as farEnvelope, faLamp, faTerminal, faMailbox,
  faFileExcel, faFilePdf, faFileCsv, faFile,} from '@fortawesome/pro-regular-svg-icons'
import {  faAngleDown as falAngleDown, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight,
  faExternalLinkAlt as falExternalLinkAlt, faCompressAlt as falCompressAlt,
  faExpandAlt as falExpandAlt, faHome} from '@fortawesome/pro-light-svg-icons'
import { faTwitter, faFacebookF, faGooglePlusG, faLinkedinIn  } from '@fortawesome/free-brands-svg-icons'

library.add(faCircle, faEnvelope, faAngleDown, falAngleDown, faCompressAlt, faExpandAlt,
  farArrowToLeft, farArrowToRight,
  farEnvelope, faLamp, faTerminal, faMailbox,
  faFileExcel, faFilePdf, faFileCsv, faFile,
  falQuoteLeft, falQuoteRight,
  falCompressAlt, falExpandAlt,
  faTwitter, faFacebookF, faGooglePlusG, faLinkedinIn, falExternalLinkAlt,  faHome);

Vue.component('font-awesome-icon', FontAwesomeIcon); //basic core
Vue.component('font-awesome-layers', FontAwesomeLayers); //additional layering capability


// Add Google Analytics
import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-71264118-1',
  checkDuplicatedScript: true,
  debug: {
    enabled: false, //set to true for console debug
    sendHitTask: isLive //if false will do everything apart from send stuff
  },
  // this local storage is set by cookie consent
  disabled: !(localStorage['cookie:accepted']),
  router
});

/*
case 'untangl.co.uk':
gaProp = 'UA-71264118-1';//production
gaTag ='GTM-K7NFGM';
break;
case 'porter.qa.untangl.uk':
gaProp = 'UA-78953759-1';//qa
gaTag ='GTM-KJFZVW';
*/


// Add Contentful-vue plugin support
// We used to import ContentfulVue from 'contentful-vue'; but this plugin does not support the preview API
// so as its trivial a modified version of it is included on the next few lines
let client = null;
const ContentfulVue = {
  install(Vue, options) {
    client = Contentful.createClient({
      space: options.space,
      accessToken: options.accessToken,
      host: options.host
    });
    Vue.prototype.$contentful = client;
  },
};
// end on modified contentful-vue

Vue.use(ContentfulVue, {
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: isLive?process.env.VUE_APP_CDA_TOKEN:process.env.VUE_APP_CDP_TOKEN,
  host: isLive?'cdn.contentful.com':"preview.contentful.com"
});

// Add cookie support
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

// Add metadata support
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Add Tidio chat support
import VueTidio from 'vue-tidio';
Vue.use(VueTidio, { appKey: 'k2p1t6hv1mwyhkhphfztfusgleflnnrs', delay: 5000 });

// Sort out some logging
import VueLogger from 'vuejs-logger';
const options = {
  isEnabled: true,
  logLevel : isLive ? 'info' : 'debug',
  stringifyArguments : true,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

import {i18n} from '@/i18n'

//Initialise Vue by setting appropriate options
// - create and then mount to element id #app (provided by App.vue)
const i = new Vue({
  router,
  store,
  i18n,
  render: h => h(App) //render functions are complex and I don't know what this means yet!
}).$mount('#app');

i.$log.debug('Main.js running, our host is:', location.host);
i.$log.debug('Main.js running, our environment is:', process.env);

//Deliberately initialise libraries passing log instance somehow????

