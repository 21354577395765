/**
 * Books state
 *
 * This store holds state pertaining to books. Effectively it is used as a cache for books contents lists
 * so that we dod not fetch them constantly.
 *
 * NB this is a Vuex store module so there is no Vuex specifically in here, see store.js up one directory.
 */

// Add Contentful-vue plugin support
// We used to import ContentfulVue from 'contentful-vue'; but this plugin does not support the preview API
// so as its trivial a modified version of it is included on the next few lines
import Vue from 'vue'
import * as Contentful from 'contentful';
import * as SymbolTree from 'symbol-tree';

const isLive = (document.location.host === 'untangl.co.uk');

let client = null;
const ContentfulVue = {
  install(Vue, options) {
    client = Contentful.createClient({
      space: options.space,
      accessToken: options.accessToken,
      host: options.host
    });
    Vue.prototype.$contentful = client;
  },
};
// end on modified contentful-vue

Vue.use(ContentfulVue, {
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: isLive?process.env.VUE_APP_CDA_TOKEN:process.env.VUE_APP_CDP_TOKEN,
  host: isLive?'cdn.contentful.com':"preview.contentful.com"
});


//Logging
import VueLogger from 'vuejs-logger';
const options = {
  isEnabled: true,
  logLevel : isLive ? 'info' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

let vm = new Vue();
vm.$log.debug('****TEST**** Whats our node ', process.env.NODE_ENV);


// initial state
// *************
let state = {
  /*
  Each book will held as a property:
  state[name].contents
  state[name].currentPage - identified by its slug
  state[name].loading - true whilst loading
  state[name].error - true if loading error

  If we don't define them at the start then they will not be reactive hence a list of supported books
  */
};
const bookList =['UntanglerAPI', 'Configuration', 'AnalysesRef'];
state = bookList.reduce((acc, book)=>{
  acc[book] = {};
  acc[book].contents = {};
  acc[book].showContents = true; //expand the contents list
  acc[book].currentPage = '';
  acc[book].loading = false;
  acc[book].error = false;
  acc[book].loaded = false; //have we cached the book contents list
  return acc;
}, {});
vm.$log.debug('{state} initial value:', state, {});


/*
Vuex allows us to define "getters" in the store. You can think of them as computed properties for stores. Like computed properties, a getter's result is cached based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
*/
// getters
// *******
const getters = {
  //bookContents return data like this:
  /*
    let v = [
      {"slug": "untangler-restful-api", "title": "Untangler RESTful API", "level": 0},
      {
        "slug": "api-overview",
        "title": "API Overview",
        "level": 1,
        "children": [{
          "slug": "authorisation-and-endpoint-security",
          "title": "Authorisation and Endpoint Security",
          "level": 2,
          "children": [
            {"slug": "json-web-token", "title": "JSON Web Token", "level": 2},
            {
              "slug": "obtaining-a-jwt-token",
              "title": "Obtaining a JWT Token",
              "level": 3,
              "children": []
            }
          ]
        }]
      },
      {
        "slug": "responses", "title": "Responses", "level": 1,
        "children": []
      }
    ];
  */

  bookTitleAndSummary:(state) => (book) => {
    let res = {title:"", summary:""};
    if (state[book].contents.root) { //NB root of the book is the cover page
      res.title = state[book].contents.root.title;
      // res.summary = this.convertToHTML(state[book].contents.root.pageContent); //TODO
    }
    // return the cover
    vm.$log.debug('{getter bookTitleAndSummary} res:', res, {});
    return res;
  },

  currentPage:(state) => (book) => {
    let res = "";
    if (state[book].currentPage !== undefined) {
      res = state[book].currentPage;
    }
    // return the current page slug
    return res;
  },

  showContents:(state) => (book) => {
    let res = true;
    if (state[book].showContents !== undefined) {
      res = state[book].showContents;
    }
    // return the current contents display state
    vm.$log.debug('{getter showContents} res:', res, {});
    return res;
  },

  bookContents:(state) => (book) => {
    vm.$log.debug('{getter bookContents} book:', book, {});
    vm.$log.debug('{getter bookContents} state[book].currentPage:', state[book].currentPage, {});
    let res = [];
    // return the contents as an Array of nested Arrays (just like Contentful original!)
    if (state[book].contents.tree) {
      // the root is the cover page, we want its children
      for (let child of state[book].contents.tree.childrenIterator(state[book].contents.root)) {
        res.push(writeArrayPage(state[book].contents.tree, child));
      }
    }
    vm.$log.debug('{getter bookContents} res:', res, {});

    return res;
  },

  /**
   * bookNav - given the current page return navigation for previous and next pages
   *
   * @param book
   * @return {function(*=): Array}
   */
  bookNav:(state, getters) => (book) => {
    vm.$log.debug('{getter bookNav} book:', book, {});
    //get the current slug and find the tree item it refers to then return before and after items
    let cp = getters['currentPage'](book);
    let pageObj;
    let v = {
      prev: {text:"Previous", href:""},
      next: {text:"Next", href:""},
    };
    vm.$log.debug('{getter bookNav} cp:', cp, {});
    // find our current page object from the slug
    if (state[book].contents.tree) {
      pageObj = Array.from(state[book].contents.tree.treeIterator(state[book].contents.root))
        .find(page => page.slug === cp);
      vm.$log.debug('{getter bookNav} pageObj:', pageObj, {});
    }
    if (pageObj !== undefined){
      let prev = state[book].contents.tree.preceding(pageObj);
      if (prev === null) {
        prev = {title : "", slug:""};
      }
      let next = state[book].contents.tree.following(pageObj);
      if (next === null) {
        next = {title : "", slug:""};
      }
      vm.$log.debug('{getter bookNav} prev:', prev);
      vm.$log.debug('{getter bookNav} next:', next);
      return {
        prev: {text:prev.title, href:"/book/"+book+"/"+prev.slug},
        next: {text:next.title, href:"/book/"+book+"/"+next.slug},
      };
    }
    else { //can happen on the contents page
      return {
        prev: {text:"", href:""},
        next: {text:"", href:""},
      };
    }

  }
};


/*
Actions are similar to mutations, the differences being that:
  Instead of mutating the state, actions commit mutations.
  Actions can contain arbitrary asynchronous operations.
*/

// actions
// *******
const actions = {
  async getBookContents({commit, state, rootState}, bookName) {
    vm.$log.debug(`{getBookContents} getBookContents called for book ${bookName}`);
    commit('bookLoadErr', {book: bookName, error: false}); //clear any lingering error

    vm.$log.debug('{getBookContents}  fstate:', state[bookName],{});
    if (state[bookName].loaded) return; //return cached version

    commit('bookLoading', {book: bookName, loading: true}); //set loading flag

    const query = {
      'content_type': process.env.VUE_APP_BOOKPAGE_TYPE_ID,
      'fields.book': bookName,
      'fields.frontPage': true,
      include: 5, //maximum number of levels in hierarchy
      select: 'fields.title,fields.slug,fields.children' //even with this will still return all fields for children
    };

    await vm.$contentful
      .getEntries(query)
      .then((res) => {
        // vm.$log.debug('{getBookContents}  result:', JSON.stringify(res, null, 2));
        // Before we muck around set the current page to the front page
        // commit('pageUpdate', {book: bookName, slug: res.items[0].fields.slug});
        // restructure to give us internal tree structure of titles + slugs + generate numbering
        // each page is held as a property (based on the unique slug) of the book object and then the tree structure
        // overlaid using symbol-tree
        const tree = new SymbolTree(); //tree object through which to link everything

        const frontPage = writePage(tree, res.items[0]);

        vm.$log.debug('{getBookContents} tree:', tree, {});
        vm.$log.debug('{getBookContents} frontPage:', frontPage, {});
        vm.$log.debug('{getBookContents} test tree get last child:', tree.lastChild(frontPage), {});

        commit('contentsUpdate', {book: bookName, contents: {tree:tree, root:frontPage}}); //save the book outline
        commit('bookLoaded', {book: bookName, loaded: true}); //mark as cached
      })
      .catch((error) => {
        vm.$log.debug('{getBookContents}  failed with error:', JSON.stringify(error, null, 2));
        commit('bookLoadErr', {book: bookName, error: error}); //set error
      })
      .finally(() => {
        commit('bookLoading', {book: bookName, loading: false}); //finished loading
      });
  }
};

/*
The only way to actually change state in a Vuex store is by committing a mutation. Vuex mutations are very similar
to events: each mutation has a string type and a handler. The handler function is where we perform actual state
modifications, and it will receive the state as the first argument:
You cannot directly call a mutation handler. Think of it more like event registration: "When a mutation with type
increment is triggered, call this handler."
You can pass an additional argument to store.commit, which is called the payload for the mutation:
e.g store.commit('increment', 10)
In most cases, the payload should be an object so that it can contain multiple fields, and the recorded mutation
will also be more descriptive.
*/

// mutations
// *********
const mutations = {

  /**
   * bookLoading - sets a loading flag for a book
   *
   * @param state
   * @param bookAndLoading Object - {book: bookName, loading: true/false}
   */
  bookLoading: (state, bookAndLoading) => {
    // if (!state[bookAndLoading.book]) {
    //   state[bookAndLoading.book] = {}; //initialise storage if needed
    // }
    state[bookAndLoading.book].loading = bookAndLoading.loading;
    vm.$log.debug(`{mutation} bookLoading for book ${bookAndLoading.book} set to ${bookAndLoading.loading}`);
  },

  bookLoaded: (state, bookAndLoaded) => {
    state[bookAndLoaded.book].loaded = bookAndLoaded.loaded;
    vm.$log.debug(`{mutation} bookAndLoaded for book ${bookAndLoaded.book} set to ${bookAndLoaded.loaded}`);
  },

  bookLoadErr: (state, bookLoadError) => {
    // if (!state[bookLoadError.book]) {
    //   state[bookLoadError.book] = {}; //initialise storage if needed
    // }
    vm.$log.debug(`{mutation} bookLoadErr for book ${bookLoadError.book} set to ${bookLoadError.error}`);
    state[bookLoadError.book].error = bookLoadError.error;
  },

  pageUpdate: (state, bookAndPage) => {
    // if (!state[bookAndPage.book]) {
    //   state[bookAndPage.book] = {}; //initialise storage if needed
    // }
    vm.$log.debug(`{mutation} pageUpdate for book ${bookAndPage.book} set to ${bookAndPage.slug}`);
    state[bookAndPage.book].currentPage = bookAndPage.slug;
  },

  contentsUpdate: (state, bookAndContents) => {
    // if (!state[bookAndContents.book]) {
    //   state[bookAndContents.book] = {}; //initialise storage if needed
    // }
    //Use object deconstruction to create new object so reactivity sees it
    state[bookAndContents.book] = {...state[bookAndContents.book], contents: bookAndContents.contents};
  },

  /**
   * toggleShowContents - sets/clears display flag for a book contents list
   *
   * @param state
   * @param bookAndShowContents Object - {book: bookName, showContents: true/false}
   */
  toggleShowContents: (state, bookAndShowContents) => {
    // if (!state[bookAndContents.book]) {
    //   state[bookAndContents.book] = {}; //initialise storage if needed
    // }
    //Use object deconstruction to create new object so reactivity sees it
    vm.$log.debug(`{mutation} toggleShowContents for book ${bookAndShowContents.book} set to ${bookAndShowContents.showContents}`);
    state[bookAndShowContents.book] = {...state[bookAndShowContents.book], showContents: bookAndShowContents.showContents};
  }

};

//utility functions
/**
 *
 * @param tree
 * @param page {Object} - has properties fields.title etc and fields.children which is an array of pages
 * @return {{slug2: {title: string}, slug1: {title: string}}}
 */
const writePage = function(tree, page, level=0) {
  //returns a load of simple objects each representing a page linked to a tree with symbols
  let iam = {slug: page.fields.slug, title: page.fields.title, level: level};
  if (page.fields.children && page.fields.children.length>0){
    page.fields.children.forEach((cur,idx,src)=>{
      let child = writePage(tree, cur, level+1);
      tree.appendChild(iam, child);
    })
  }
  return iam;
};

const writeArrayPage = function(tree, page) {
  //returns a load of simple objects each representing a page linked to a tree with symbols
  let iam = {slug: page.slug, title: page.title, children:[]};
  for (let child of tree.childrenIterator(page)) {
    iam.children.push(writeArrayPage(tree, child));
  }
  return iam;
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}