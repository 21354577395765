/*
Set up internationalisation support todo dynamically load and change locale
*/

import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

function loadLocaleMessages () {
  // require.context is some webpack magic that I don't understand!
  const locales = require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

let i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages('en')
})

//named export
export { i18n }

