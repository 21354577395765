<template>
  <footer class="footer">

    <div class="container">
      <div class="columns is-centered has-text-centered ">
        <div v-show="profile.contact.twitter" class="column  is-1">
          <div class="icon is-medium"> <!--Sets the overall size whilst rendering-->
            <a :href="profile.contact.twitter" target="untangl_docs">
              <font-awesome-layers class="fa-3x"> <!--Sets the size of the icon-->
                <font-awesome-icon icon="circle"/>
                <font-awesome-icon :icon="['fab', 'twitter']" transform="shrink-6" class="has-text-white"/>
              </font-awesome-layers>
            </a>
          </div>
        </div>
        <!--<div v-show="profile.contact.fb" class="column is-1 ">-->
        <!--<div class="icon is-medium">-->
        <!--<a :href="profile.contact.fb" target="untangl_docs">-->
        <!--<font-awesome-layers class="fa-3x">-->
        <!--<font-awesome-icon icon="circle"/>-->
        <!--<font-awesome-icon :icon="['fab', 'facebook-f']" transform="shrink-6" class="has-text-white"/>-->
        <!--</font-awesome-layers>-->
        <!--</a>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div v-show="profile.contact.google" class="column is-1 ">-->
        <!--<div class="icon is-medium">-->
        <!--<a :href="profile.contact.google" target="untangl_docs">-->
        <!--<font-awesome-layers class="fa-3x">-->
        <!--<font-awesome-icon icon="circle"/>-->
        <!--<font-awesome-icon :icon="['fab', 'google-plus-g']" transform="shrink-6 left-1" class="has-text-white"/>-->
        <!--</font-awesome-layers>-->
        <!--</a>-->
        <!--</div>-->
        <!--</div>-->
        <div v-show="profile.contact.linkedin" class="column is-1 ">
          <div class="icon is-medium">
            <a :href="profile.contact.linkedin" target="untangl_docs">
              <font-awesome-layers class="fa-3x">
                <font-awesome-icon icon="circle"/>
                <font-awesome-icon :icon="['fab', 'linkedin-in']" transform="shrink-6" class="has-text-white"/>
              </font-awesome-layers>
            </a>
          </div>
        </div>
        <div v-show="profile.contact.email" class="column is-1 ">
          <div class="icon is-medium">
            <a class=""
               :href="'mailto:'+profile.contact.email+'?Subject=From website'" target="untangl_docs">
              <font-awesome-layers class="fa-3x">
                <font-awesome-icon icon="circle"/>
                <font-awesome-icon :icon="['fas', 'envelope']" transform="shrink-6" class="has-text-white"/>
              </font-awesome-layers>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="columns is-centered">
        <div class="column is-6">
          <nav class="level is-uppercase ">
            <router-link v-show="profile.contact.type == 'us'"
                         :to="{ name:'info', params: { arg:'support' }}"
                         class="level-item is-primary">Support
            </router-link>
            <!--<a v-show="profile.contact.type == 'us'" class="level-item is-primary" ui-sref="support">support</a>-->
            <router-link v-show="profile.contact.type == 'us'"
                         :to="{ name:'info', params: { arg:'contact' }}"
                         class="level-item is-primary">Contact
            </router-link>
            <!--<a class="level-item is-primary" ui-sref="legal">legal</a>-->
            <router-link v-show="profile.contact.type == 'us'"
                         :to="{ name:'info', params: { arg:'privacy' }}"
                         class="level-item is-primary">Privacy
            </router-link>
            <!--<a class="level-item is-primary" ui-sref="privacy">privacy</a>-->
            <!--<a v-show="profile.contact.type == 'us'" class="level-item is-primary" ui-sref="partners">partners</a>-->
            <!--<a v-show="profile.contact.type == 'us'" class="level-item is-primary" ui-sref="press">press</a>-->
          </nav>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="columns is-centered">
        <div class="column is-10">
          <p itemscope itemtype="http://schema.org/Organization" class="is-size-7 has-text-centered">
            ©2016-23 Untangl Limited. <span itemprop="legalName">{{profile.contact.name}}</span> Registered in England
            No.
            09167565
            Registered
            Office: <span itemprop="address">80-83 Long Lane, London EC1A 9ET.</span>
          </p>
        </div>
      </div>
    </div>

    <cookie-law
        v-on:accept="enableAnalytics"
        theme="untangl"
    >
      <div slot-scope="props">
        <div class="columns is-vcentered">
          <div class="column">
            <div>
              We use cookies to ensure you get the best experience on our website, see our
              <router-link
                  :to="{ name:'info', params: { arg:'privacy' }}"
              > privacy notice
              </router-link>
              for more details.
            </div>
          </div>
          <div class="column is-narrow">
            <div class="spaced has-text-centered">
              <button class="button is-outlined is-radiusless is-uppercase " @click="props.accept"><span>Accept</span>
              </button>
            </div>
          </div>
        </div>
        <div class="close">
          <a @click="props.close" class="delete"></a>
        </div>
      </div>
    </cookie-law>

  </footer>
</template>

<script>
  import {mapState /*, mapActions */} from 'vuex'
  // Add our cookie consent
  import CookieLaw from 'vue-cookie-law'

  export default {
    name: 'Footer',
    props: {
      msg: String
    },
    computed: mapState({
      profile: state => state.profile
    }),
    methods: {
      enableAnalytics() {
        this.$ga.enable();
      }
    },
    components: {CookieLaw}

  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  @import "@/scss/_bulmaOverridden";


  .Cookie--untangl {
    color: $white;
    background-color: $textgrey;
    height: auto;
    padding: 1em;
    /*margin: 1em;*/

    a {
      color: $grey-lighter;
    }
  }

  .spaced {
    padding-left: 1em;
    padding-right: 1em;
    margin: auto 0 auto 0;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
  }

</style>
