/*
This file manages the separate modules of the Vuex store.
In here we assemble the modules and export the store.
 */

import Vue from 'vue'
import Vuex from 'vuex'
//Modules
import profile from './modules/profile'
// import pagedata from './modules/pagedata'
import menu from './modules/menu'
import books from './modules/books'
import auth from './modules/auth'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    profile,
    books,
    // pageData,
    menu,
    // auth //todo not used yet - same as vue-untangler
  },

/*
  //root actions could go here

  state: {

  },
  mutations: {

  },
  actions: {

  }
*/
})
