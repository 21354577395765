<!--
Responsible for dynamically loading a Panel.
Call this component like this:
<Pagelet :pageData="someData" :type="templateType" />
The templateType will load up an appropriate rendering component which will use the data contained in
someData as needed.
(How it works: uses dynamic imports from Webpack. Used together with computed values this is where
the magic happens– yes, computed values can return a function - whatever that means).
-->

<template>
  <component
          :is="component"
          :pageData="pageData"
          :rootPageData="rootPageData"
          :background="background"
          :size = "size"
          :type="type"
          v-if="component" />
</template>

<script>
  export default {
    name: 'Pagelet',
    props: ['pageData', 'rootPageData', 'background', 'type', 'size'],
    data: function() {
      return {
        component: null,
      }
    },
    computed: {
      loader() {
        this.$log.debug("{loader} this.type:", this.type);
        if (!this.type) {
          return null
        }
        if (this.type.match(/Panel/g)) {
          return () => import(`./panels/${this.type}`)
        }
        return () => import(`./${this.type}`)
      }
    },
    mounted() {
      if (this.loader) {
        this.loader()
          .then(() => {
            this.component = () => this.loader()
          })
          .catch(() => {
            this.component = () => import('./LoadError')
          })
      }
    },
  }
</script>

