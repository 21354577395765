/**
 * This module is a mixin/library providing support for Axios based requests
 * Note it is NOT a real Vue mixin
 */


const isLive = (document.location.host === 'untangl.co.uk');

//Logging
import Vue from 'vue'
import VueLogger from 'vuejs-logger';
import {isNil} from 'lodash';
import {i18n} from '@/i18n'


const options = {
  isEnabled: true,
  logLevel: isLive ? 'info' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

let vm = new Vue();

import axios from 'axios';

/**
 * AXIOS - partial factory function that returns a Vuex Action function that makes a axios request
 * and handles the response.
 *
 * NB there is no getters or state supplied so use rootGetters (and add rootStae if we ever need it!)
 *
 * @param handler {Object} - an object comprising three functions:
 *  @param init {function(params{Object})} - a function that returns a request object
 *  @param response {function(jsendResp{Object})} - a function that handles the response jsend object
 *  @param failing
 * @returns {function(...[*]=)}
 * @constructor
 */
const AXIOS = function({init, response, failing}) {

 return async function({commit, rootGetters, dispatch}, {params}) {
   vm.$log.debug("{AXIOS} starting request with params:", params, {});
   // ***** initialisations and setting up of req - run init and add in common stuff
   const commonReq = {
     // method etc.
     validateStatus: function (status) {
       return status >= 200 && status < 600; // handle all return codes without a rejection
     },
     timeout: 10000, //10S
   };
   // init generates the key parts of the request object - can overwrite common request props
   const req = {...commonReq, ...init({commit, rootGetters, dispatch}, params)};
   let errObj = {name:'', message:'', level:'modal'}; //to hold any errors from request
   let jsendResp;
   try {
     // start a whirlygig or something
     await dispatch('statemachine/sendLoadingEvent',{type:'START_LOAD'},{root:true});
     let res = await axios(req);
     vm.$log.debug("{AXIOS} res:", res);
     jsendResp = res.data;
     if (jsendResp && jsendResp.status){ //its not a jsend response unless it has a status
       if (jsendResp.status === "success") {
         // ***** handle response stuff eg
         // commit('saveResponse', jsendResp.data);
         let ret = await response({commit, rootGetters, dispatch}, jsendResp);
         ret = ret || true; // if nothing is returned default to true
         // stop the whirlygig
         await dispatch('statemachine/sendLoadingEvent',{type:'END_LOAD'},{root:true});
         vm.$log.debug(`{AXIOS} response:`, ret, {});
         return ret;
       }
       else if (jsendResp.status === "fail") {
         // Generally we look for the error name and translate it. If there isn't one we stick to English
         // Generic error message if we can do no better
         if (jsendResp.name) {
           errObj.name = jsendResp.name;
           errObj.message = i18n.t(`errors.${errObj.name}`)
         }
         else {
           errObj.name = "untangler_decode_error";
         }
         let errorValue; // to hold any extra values for error messages
         // check for 401 (authenticated - terrible)/403 (authorised - bad) indicating failure
         // 401 - force a sign-in
         if (res.status && res.status === 401) {
           vm.$log.warn(`{AXIOS} ${res.status} response`);
           // Log a message
           await dispatch('statemachine/LOG_ERROR', errObj, {root:true});
           // Stop loading whirlygig
           await dispatch('statemachine/sendLoadingEvent',{type:'END_LOAD'},{root:true});
           // clean up (sign-out)
           await dispatch('statemachine/REDIRECT', 'signout', {root:true});
           return false; //NB omit general request failure processing
         }
         // 403 - just tell them the bad news - unless its JWT expired
         else if (res.status && res.status === 403) {
           vm.$log.warn(`{AXIOS} ${res.status} response`);
           if (errObj.name === 'TokenExpiredError') {
             // Log a message
             await dispatch('statemachine/LOG_ERROR', errObj, {root:true});
             // Stop loading whirlygig
             await dispatch('statemachine/sendLoadingEvent',{type:'END_LOAD'},{root:true});
             // clean up (sign-out)
             await dispatch('statemachine/REDIRECT', 'signout', {root:true});
             return false; //NB omit general request failure processing
           }
           errObj.level = "transient";
           // go on to general failure processing
         }
         //Make the error messages more useful by adding some detail
         else if (jsendResp.name && jsendResp.name === "untangler_decode_error") {
           if (jsendResp.data && jsendResp.data.type) {
             errorValue = " - "+ jsendResp.data.type;
           }
           errObj.message = i18n.t(`errors.${errObj.name}`, {value: errorValue});
         }
         else if (jsendResp.name && (jsendResp.name === "MongoError" ||
           jsendResp.name === "TypeError" || jsendResp.name === "Error")) {
           if (jsendResp.message) {
             errorValue = " - "+ jsendResp.message;
           }
           errObj.message = i18n.t(`errors.${errObj.name}`, {value: errorValue});
         }
         vm.$log.error("{AXIOS} fail response:", jsendResp);
       }
       else { // status == error
         vm.$log.error("{AXIOS} error response:", jsendResp);
         errObj.message = res.statusText ? res.statusText : "";
         errObj.message += jsendResp.message ? " - "+jsendResp.message : " - "+jsendResp;
       }
       //End of json response processing
     }
     else { //404, timeout or some such fatal error - NO jsend reponse
       vm.$log.warn("{AXIOS} failed no jsendResp");
       if (res.status && res.status === 404) {
         errObj.name = 'NotFound404';
         errObj.message = i18n.t(`errors.${errObj.name}`);
       }
       else {
         errObj.name = 'Timeout';
         errObj.message = i18n.t(`errors.${errObj.name}`);
       }
     }
   }
   //the action has failed - NO jsend reponse
   catch (err) {
     vm.$log.error("{AXIOS} error:", err);
     errObj.message = err;
   }
   //all failing paths end up here
   // ***** customised event eg
   // await dispatch('statemachine/sendUIEvent',{type:'DID_NOT_UNTANGL'},{root:true});
   // NB jsendResp can be undefined if we have a complete failure so test for condition if using it
   const message = await failing({commit, rootGetters, dispatch}, jsendResp);
   if (message) {
     errObj.message = message; //override standard message if supplied
   }
   // Log a message
   await dispatch('statemachine/LOG_ERROR', errObj, {root:true});
   // Stop loading whirlygig
   await dispatch('statemachine/sendLoadingEvent',{type:'END_LOAD'},{root:true});
   return false;
 }

};

// Use named exports so we can individually deconstruct them on import e.g.
// import {AXIOS} from '../../mixins/axios'
export  {

  AXIOS,

};

