/**
 * menu state
 *
 * This store holds state pertaining to the header menu. There are 3 states, one for
 * each of the three products. The UNTANGLER state is entered initially or when the Untangl logo clicked.
 *
 * NB this is a Vuex store module so there is no Vuex specifically in here, see store.js up one directory.
 */

const isProduction = process.env.NODE_ENV === 'production';

//Logging
import Vue from 'vue'
import VueLogger from 'vuejs-logger';
const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

let vm = new Vue();
// vm.$log.debug('****TEST**** Whats our node ', process.env.NODE_ENV);

// menu states
const HOME = 0; //when on the home page
// const GENERAL = 1;
// const OTHER = 2; //other is one of the solutions
const ONE = 3; // These 2 entered from "hidden" links, exited on any aother item being selected
const SELECT = 4;
const UNTANGLER = 5; //normal default when not on the home page

// initial state
// *************
const state = {
  menuState: UNTANGLER, // which of three menu states we are in
  showSignin: false, // whether to show a sign-in button for the apps
  signinRouteName: '', // the name of the route to go to to sign-in (see router.js)
};


/*
Vuex allows us to define "getters" in the store. You can think of them as computed properties for stores. Like computed properties, a getter's result is cached based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
*/
// getters
// *******
const getters = {};

/*
Actions are similar to mutations, the differences being that:
  Instead of mutating the state, actions commit mutations.
  Actions can contain arbitrary asynchronous operations.
*/

// actions
// *******
const actions = {
};

/*
The only way to actually change state in a Vuex store is by committing a mutation. Vuex mutations are very similar
to events: each mutation has a string type and a handler. The handler function is where we perform actual state
modifications, and it will receive the state as the first argument:
You cannot directly call a mutation handler. Think of it more like event registration: "When a mutation with type
increment is triggered, call this handler."
You can pass an additional argument to store.commit, which is called the payload for the mutation:
e.g store.commit('increment', 10)
In most cases, the payload should be an object so that it can contain multiple fields, and the recorded mutation
will also be more descriptive.
*/

// mutations
// *********
const mutations = {
  pageUpdate: (state, route) => {
    // console.log("{mutation} route:", route,{});
    if (route.path.search(/^\/external/) > -1) return;
    state.showSignin = false;
    //use the route to determine the changestate required
    if (route.name === 'products' &&
      route.params &&
      route.params.arg) {
      switch (route.params.arg) {
        case 'untangler':
        default:
          state.menuState = UNTANGLER;
          state.signinRouteName = 'untanglersignin';
          state.showSignin = true;
          break;
      }
    }

    // legacy products are available but click on a non legacy link and its back to Untangler
    else if (route.name === 'productslegacy' &&
      route.params &&
      route.params.arg) {
      switch (route.params.arg) {
        case 'untangl-select':
          state.menuState = SELECT;
          break;
        case 'untangl-one':
          state.menuState = ONE;
          //We want a sign in for Untangler and One only
          state.signinRouteName = 'quotesignin';
          state.showSignin = true;
          break;
        default:
          state.menuState = UNTANGLER;
          break;
      }
    }

    // now info links
    else if (route.name === 'info' &&
      route.params &&
      route.params.arg) {
      switch (route.params.arg) {
        case 'tech-one':
          state.menuState       = ONE;
          state.signinRouteName = 'quotesignin';
          state.showSignin      = true;
          break;
        case 'tech-select':
          state.menuState       = SELECT;
          state.showSignin      = false;
          break;
        case 'tech-untangler':
        case 'key-features-untangler':
        case 'pricing':
          state.menuState = UNTANGLER;
          state.signinRouteName = 'untanglersignin';
          state.showSignin = true;
          break;
        default:
          state.menuState = UNTANGLER;
      }
    }

    // now books
    else if (route.name === 'book' &&
      route.params &&
      route.params.bookname) {
      switch (route.params.bookname) {
        case 'AnalysesRef':
          state.menuState       = UNTANGLER;
          state.signinRouteName = 'untanglersignin';
          state.showSignin      = true;
          break;
        case 'Configuration':
          state.menuState       = UNTANGLER;
          state.signinRouteName = 'untanglersignin';
          state.showSignin      = true;
          break;
      }
    }
    //now faqs
    else if (route.name === 'faqs' &&
      route.params &&
      route.params.arg) {
      switch (route.params.arg) {
        case 'untangler':
          state.menuState       = UNTANGLER;
          state.signinRouteName = 'untanglersignin';
          state.showSignin      = true;
          break;
        case 'untangl-one':
          state.menuState       = ONE;
          state.signinRouteName = 'quotesignin';
          state.showSignin      = true;
          break;
        case 'untangl-select':
          state.menuState       = SELECT;
          state.showSignin      = false;
          break
        default:
          state.menuState = UNTANGLER;
      }
    }

    //now miscellaneous
    else if (route.name === 'api') {
      state.menuState       = UNTANGLER;
      state.signinRouteName = 'untanglersignin';
      state.showSignin      = true;
    }

    //Home!!
    else if (route.name === 'home') {
      state.menuState       = HOME;
      state.showSignin      = false;
    }
    else {
      state.menuState = UNTANGLER;
    }
  }

};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
