<!--Top level file for the whole of the application-->
<template>
  <div id="app">
    <router-view name="header"></router-view>
    <router-view></router-view> <!--default-->
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  // use lifecycle hook to add or remove class on the body tag for fixed menu positioning
  export default {
    methods: {
      toggleBodyClass(addRemoveClass, className) {
        const el = document.body;

        if (addRemoveClass === 'addClass') {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      },
    },
    mounted() {
      this.toggleBodyClass('addClass', 'has-navbar-fixed-top');
    },
    destroyed() {
      this.toggleBodyClass('removeClass', 'has-navbar-fixed-top');
    }
  }
</script>

<style lang="scss">
  /*Bulma style changes*/
  @import 'scss/bulmaOverridden';
  /*Other styles*/
  @import './scss/style.scss';
</style>
