<!--
Standard header for all pages including menu
NOTE menu.js is closely coupled with this page
-->
<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link
          :to="{ name:'home'}"
          class="navbar-item ">
        <figure>
          <img :src="profile.landf.logo" class="is-162x46">
        </figure>
      </router-link>

      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" @click="isOpen = !isOpen"
         v-bind:class="{'is-active': isOpen}">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" v-bind:class="{'is-active': isOpen}" @click="isOpen = !isOpen">

      <div class="navbar-start">
      </div>

        <div class="navbar-end is-uppercase">


          <!--Home symbol shown when not on home page-->
          <router-link v-show="showHomeButton"
              :to="{name: 'home'}"
              class="navbar-link has-text-white is-arrowless">
              <font-awesome-icon :icon="['fal', 'home']" class="has-text-white"/>
          </router-link>

          <!--Solutions dropdown menu-->
          <div class="navbar-item has-dropdown is-hoverable" >
            <router-link
                :to="{ name:'info', params: { arg:'solutions' }}"
                class="navbar-item has-text-white is-arrowless">Solutions
            </router-link>
            <div class="navbar-dropdown has-text-white">
              <router-link
                  :to="{ name:'info', params: { arg:'life' }}"
                  class="navbar-item has-text-white">Group Life Insurance
              </router-link>
              <router-link
                  :to="{ name:'info', params: { arg:'re' }}"
                  class="navbar-item has-text-white">Reinsurance
              </router-link>
              <router-link
                  :to="{ name:'info', params: { arg:'eb' }}"
                  class="navbar-item has-text-white">Employee Benefits
              </router-link>
            </div>
          </div>

          <!--Product dropdown menu-->
          <div class="navbar-item has-dropdown is-hoverable" >
            <!--Name of Product-->
            <router-link
                :to="allProdMenuItem.to"
                class="navbar-link has-text-white is-arrowless">
              {{allProdMenuItem.label}}
            </router-link>

            <!--UNTANGLER-->
            <div class="navbar-dropdown has-text-white" v-if="showUntangler">
              <router-link
                  :to="{ name:'info', params: { arg:'key-features-untangler' }}"
                  class="navbar-item has-text-white">Key Features</router-link>
              <router-link
                  :to="singleProdTechMenuItem.to"
                  class="navbar-item has-text-white">Technology
              </router-link>
              <router-link
                  :to="{ name:'info', params: { arg:'pricing' }}"
                  class="navbar-item has-text-white">Pricing
              </router-link>
              <router-link
                  :to="singleProdFAQMenuItem.to"
                  class="navbar-item has-text-white">FAQ
              </router-link>
              <hr class="navbar-divider">
              <router-link
                  :to="{ name:'book', params: { bookname:'Configuration', slug:'untangler-configuration-guide' }}"
                  class="navbar-item has-text-white">Config Guide
              </router-link>
              <router-link
                  :to="{ name:'book', params: { bookname:'AnalysesRef', slug:'untangler-analyses-reference' }}"
                  class="navbar-item has-text-white">Analyses Reference
              </router-link>
            </div>
<!--
                <router-link
                    :to="{ name:'book', params: { bookname:'UntanglerAPI', slug:'untangler-restful-api-guide' }}"
                    class="navbar-item has-text-white">API Guide
                </router-link>
-->

            <!--LEGACY PRODUCTS-->
            <!--ONE-->
            <div class="navbar-dropdown has-text-white" v-if="showOne">
              <router-link
                  :to="singleProdTechMenuItem.to"
                  class="navbar-item has-text-white">Technology
              </router-link>
              <router-link
                  :to="singleProdFAQMenuItem.to"
                  class="navbar-item has-text-white">FAQ
              </router-link>
            </div>
            <!--SELECT-->
<!--
            <div class="navbar-dropdown has-text-white" v-if="showSelect">
              <router-link
                  :to="singleProdTechMenuItem.to"
                  class="navbar-item has-text-white">Technology
              </router-link>
              <router-link
                  :to="singleProdFAQMenuItem.to"
                  class="navbar-item has-text-white">FAQ
              </router-link>
            </div>
-->

          </div>

          <!--About-->
          <div class="navbar-item has-dropdown is-hoverable" >
            <router-link
                :to="{ name:'info', params: { arg:'about' }}"
                class="navbar-item has-text-white">About
            </router-link>
            <div class="navbar-dropdown has-text-white" >
              <!--Contact-->
              <router-link
                  :to="{ name:'info', params: { arg:'contact' }}"
                  class="navbar-item has-text-white">Contact
              </router-link>
              <router-link
                  :to="{ name:'info', params: { arg:'other-products' }}"
                  class="navbar-item has-text-white">Other Products
              </router-link>
<!--              <router-link-->
<!--                  :to="{ name:'info', params: { arg:'technology' }}"-->
<!--                  class="navbar-item has-text-white">Technology-->
<!--              </router-link>-->
<!--            </div>-->
            <!--TODO add in pricing to router etc. getting menu states right-->
<!--            <div class="navbar-dropdown has-text-white" >-->
            </div>
          </div>

          <!--resources-->
          <div class="navbar-item has-dropdown is-hoverable" >
            <router-link
                :to="{ name:'info', params: { arg:'resources' }}"
                class="navbar-item has-text-white">Resources
            </router-link>
            <div class="navbar-dropdown has-text-white" >
              <!--Blog-->
              <router-link
                  :to="{ name:'blog'}"
                  class="navbar-item has-text-white">Blog
              </router-link>
              <router-link
                  :to="{ name:'info', params: { arg:'videos' }}"
                  class="navbar-item has-text-white">Videos
              </router-link>
              <router-link
                  :to="{ name:'info', params: { arg:'downloads' }}"
                  class="navbar-item has-text-white">Downloads
              </router-link>
            </div>
          </div>

          <!--Sign-in button optionally shown-->
          <div class="navbar-item">
            <div class="buttons">
              <router-link
                  :to="{name:menu.signinRouteName}"
                  class="button is-white is-outlined is-radiusless"
                  :style="{visibility: menu.showSignin ? 'visible' : 'hidden'}">
                <span>Sign in</span>
                <span class="buttonicon">
                    <font-awesome-icon :icon="['fal', 'external-link-alt']"/>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
  </nav>

</template>

<script>
  import {mapState, mapActions} from 'vuex'

  // menu states
  const HOME = 0;
  const ONE = 3;
  const SELECT = 4;
  const UNTANGLER = 5;


  export default {
    name: 'Header',
    props: {
      msg: String
    },
    computed: {
      // Show different, custom product menus
      showUntangler() {return this.$store.state.menu.menuState === UNTANGLER ||
          this.$store.state.menu.menuState === HOME},
      showSelect() {return this.$store.state.menu.menuState === SELECT},
      showOne() {return this.$store.state.menu.menuState === ONE},
      showHomeButton() {
        return this.$store.state.menu.menuState !== HOME
      },

      // Should this really be getters in the menu Vuex store?
      /**
       * Used to provide top level menu item and link link depending on product
       *
       * @return {{to: {name: string, params: {arg: string}}}}
       */
      allProdMenuItem() {
        let label      = 'Untangler';
        let arg = 'general';
        let productType = 'products';
        //NB this is the way to access Vuex store inside computed properties - reactive
        switch (this.$store.state.menu.menuState) {
          case ONE:
            label      = 'Untangl One';
            arg = 'untangl-one';
            productType = 'productslegacy';
            break;
          case SELECT:
            label      = 'Untangl Select';
            arg = 'untangl-select';
            productType = 'productslegacy';
            break;
          case UNTANGLER: //This is the default
          case HOME:
            label      = 'Untangler';
            arg = 'untangler';
            productType = 'products';
            break;
          default:
            return {label: label, to: {name: 'home'}};
        }
        return {label: label, to: {name: productType, params: {arg: arg}}};
      },

      /**
       * Used to select correct FAQ link depending on product
       *
       * @return {{to: {name: string, params: {arg: string}}}}
       */
      singleProdFAQMenuItem() {
        let label      = 'Products';
        let faqProduct = 'general';
        //NB this is the way to access Vuex store inside computed properties - reactive
        switch (this.$store.state.menu.menuState) {
          case ONE:
            label      = 'One';
            faqProduct = 'untangl-one';
            break;
          case SELECT:
            label      = 'Select';
            faqProduct = 'untangl-select';
            break;
          case UNTANGLER:
            label      = 'Untangler';
            faqProduct = 'untangler';
            break;
        }
        return {label: label, to: {name: 'faqs', params: {arg: faqProduct}}};
      },

      /**
       * Used to select correct technology link depending on product
       *
       * @return {{to: {name: string, params: {arg: string}}}}
       */
      singleProdTechMenuItem() {
        let techProduct = 'general';
        //NB this is the way to access Vuex store inside computed properties - reactive
        switch (this.$store.state.menu.menuState) {
          case ONE:
            techProduct = 'tech-one';
            break;
          case SELECT:
            techProduct = 'tech-select';
            break;
          case UNTANGLER:
            techProduct = 'tech-untangler';
            break;
        }
        return {to: {name: 'info', params: {arg: techProduct}}};
      },

      // mix this into the outer object with the object spread operator
      ...mapState({
        profile: state => state.profile,
        menu: state => state.menu,

      })
    },
    //local variable
    data: function () {
      return {
        isOpen: false //nav menu on mobile
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

  .is-162x46 {
    @include desktop {
      height: 46px;
      width: 162px;
    }

    /*

        @include mobile {
          height: 23px;
          width: 81px;
        }
    */
  }


</style>
